import Vue from 'vue'
import Vuex from 'vuex'

// import store2 from './store2';



Vue.use(Vuex)

export default new Vuex.Store({
 
  state: {
    // 定义数据
    name:"name",
    age:"20"
  },
  getters: {
    getMessage(state) {
      // 获取修饰后的name，第一个参数state为必要参数，必须写在形参上
      return ` nishi+ ${state.name}`;
    },
  },
  mutations: {
     // 增加nutations属性
     setNumber(state,payload) {
      // 增加一个mutations的方法，方法的作用是让num从0变成5，state是必须默认参数
      state.name = payload.name
    },
  },
  actions: {
      // 增加actions属性
      setNums(content,payload) {
        // 增加setNum方法，默认第一个参数是content，其值是复制的一份store
        return new Promise(resolve => {
          // 我们模拟一个异步操作，1秒后修改number为888
          setTimeout(() => {
            // console.log(payload);
           return  resolve(payload);
          }, 1000);
        });
      },

  },
  modules: {
    
  }
})
