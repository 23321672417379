import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: HomeView,
  //   meta: {
  //     title: "登录"
  //   }
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: "伊恋全屋定制订单状态查询"
    }
  }, {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: "伊恋全屋定制订单状态查询"
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  // var tokenStr = localStorage.getItem('id')

  // if (to.path === '/login') {
  // 	next()
  // }
  // if (!tokenStr) {

  // 	next('/login')

  // } else {}
  next()


})




export default router
