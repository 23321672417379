import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Button, Field, Cell, CellGroup, Toast } from 'vant';

import 'vant/lib/index.css'

Vue.use(Button).use(Cell).use(CellGroup).use(Field).use(Toast)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$toast = Toast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
