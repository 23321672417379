<template>
  <div style="background: #f5f5f5" :class="{ bot: this.data }">
    <div class="curHeader" style="background: black">
      <div class="image">
        <img
          src="@/assets/log.png"
          alt=""
          style="width: 10%; height: 10%"
        />
        <img
          src="http://4g.eelian.com.cn/assets/img/wx/PC_logo2.png"
          alt=""
          style="width: 50%; height: 15%"
        />
      </div>
    </div>
    <div
      style="
        margin: 20px 0;
        height: 30px;
        display: flex;
        width: 100%;
        justify-content: space-around;
      "
    >
      <div>
        <van-cell-group style="width: 140%; font-size: 10px">
          <van-field
            v-model="value"
            placeholder="请输入您的订单号或预留的电话号码"
          />
        </van-cell-group>
      </div>
      <div>
        <van-button type="warning" @click="query" style="margin-left: 60px"
          >查询</van-button
        >
      </div>
    </div>
    <div v-if="this.data">
      <div style="text-align: center; padding: 25px 0">
        尊敬的客户您好！查询的结果如下：
      </div>
      <div v-for="(item, index) in data" :key="index">
        <div style="margin: 20px 0">
          <van-cell-group inset>
            <van-cell
              style="background-color: #f89704; color: #fff"
              :title="'订单号：' + item.salesOrderNo"
              :value="'订单状态：' + item.status"
            />
            <van-cell :title="'客户：' + item.customerName" />
            <van-cell :title="'电话：' + item.mobile" />

            <van-cell :title="'地址：' + item.address" />
          </van-cell-group>
        </div>
      </div>
    </div>
    <img
      v-else
      src="http://4g.eelian.com.cn/assets/img/wx/footer.jpg"
      alt=""
      :style="{ width: mywidth }"
    />
    <div style="padding: 50px 0"></div>
    <div class="logo" v-if="this.data">
      <div
        style="
          width: 50%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <div
          class="logo_logo"
          :style="'background-image: url(' + logo + ')'"
        ></div>
        <div style="margin-left: 20px; height: 100%; font-size: 14px">
          <div>伊恋全屋定制</div>
          <div>400-6380-299</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vant/lib/button/style";
import axios from "axios";
export default {
  name: "DataHelloWorld",

  data() {
    return {
      value: "",
      data: null,
      logo: "https://crmeb.fooktech.cn//uploads/attach/2022/04/20220419/01bab156bc4be0520fa86b5a0312b83a.png",
      mywidth: "375rem",
      number: "",
    };
  },

  mounted() {},
  comments: {},

  methods: {
    query() {
      if (!this.value) {
        this.$toast.fail("请输入！");
      } else {
        axios
          .get(
            `https://fb.eelian.com.cn/api/front/foreign/orderQuery?searchValue=${this.value}`
          )
          .then((res) => {
            if (res.data.code == "200") {
              this.$toast.success("查询成功！");
              this.data = res.data.data;
              this.value = "";
            } else {
              this.data = null;
              this.$toast.fail("暂时没有查到订单信息！");
              this.value = "";
            }
          })
          .catch((err) => {
            this.$toast.fail("暂时没有查到订单信息！");
            this.value = "";
            this.data = null;
          });
      }
    },
  },
  created() {
    let k = document.documentElement.clientWidth;
    this.mywidth = k + "px";
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 52px;
  margin: 0 auto;
  background: white;
  position: absolute;
  bottom: 0;
  &_logo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.692);
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}
::v-deep .van-cell__value {
  color: #fff;
}
.top {
  background-color: black;
}
.image {
  margin-left: 10%;
  padding: 10px 0;
  display: flex;
}
.bot {
  background: #f2f2f2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>